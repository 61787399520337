import React, { useState } from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import {
  SponsorCard,
  PostCard,
  SeoData,
  SizingCard,
  HCPRamp,
} from "components";
import {
  H2,
  P,
  Button,
  Container,
  SmallContainer,
  TwoPieceGrid,
  ThreePieceGrid,
  Grid,
} from "styles";
import LTRSponsorImage from "../../images/ltr-logo.png";
import AsthmaSocietySponsorImage from "../../images/asthma-logo.png";
import LifeEffectsSponsorImage from "../../images/life-effects-logo.png";
import { motion, AnimatePresence } from "framer-motion";

const HCPResourcesPage = ({ data }) => {
  const [popup, setPopup] = useState(true);
  const [selected, setSelected] = useState("all");
  const { articles } = data.allMarkdownRemark.edges[0].node.frontmatter;
  let filteredArticles = articles.filter(
    (article) => article.category === selected
  );
  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.25, duration: 0.75 }}
    >
      {popup && (
        <HCPRamp setPopup={setPopup} link2="/" link1="/resources/patients" />
      )}
      <SeoData
        pageSpecificTitle="For Healthcare Professionals"
        pageSpecificDescription="The information on this part of the AeroChamber® website by Teva is suitable for, and solely intended for, IE Healthcare professionals. Contact Teva: T: +44 207 540 7117 or E: Medinfo:@tevauk.com"
      />
      <SmallContainer id="hcp">
        <H2 centered style={{ marginBottom: "5rem" }}>
          Healthcare Professionals Information
        </H2>
        <P centered>
          Clinical information and resources to help you support your patients.
          See the latest studies, research and related information.
        </P>
      </SmallContainer>
      <Container>
        {/* <ButtonRow>
          <StyledButton
            active={selected === "all"}
            onClick={() => {
              setSelected("all");
            }}
          >
            Show All
          </StyledButton>
          <StyledButton
            active={selected === "Clinical Info"}
            onClick={() => {
              setSelected("Clinical Info");
            }}
          >
            Clinical Info
          </StyledButton>
          <StyledButton
            active={selected === "Asthma"}
            onClick={() => {
              setSelected("Asthma");
            }}
          >
            Asthma
          </StyledButton>
          <StyledButton
            active={selected === "Cystic Fibrosis"}
            onClick={() => {
              setSelected("Cystic Fibrosis");
            }}
          >
            Cystic Fibrosis
          </StyledButton>
          <StyledButton
            active={selected === "COPD"}
            onClick={() => {
              setSelected("COPD");
            }}
          >
            COPD
          </StyledButton>
        </ButtonRow> */}
        <ThreePieceGrid>
          {selected === "all" && (
            <AnimatePresence exitBeforeEnter>
              {articles.map((article, i) => (
                <PostCard
                  key={i}
                  image={article.image}
                  title={article.name}
                  category={article.category}
                  description={article.description}
                  internalLink={article.internalLink}
                  link={article.link}
                  linkText="Read more"
                />
              ))}
            </AnimatePresence>
          )}
          {selected !== "all" && (
            <AnimatePresence exitBeforeEnter>
              {filteredArticles.map((article, i) => (
                <PostCard
                  key={i}
                  image={article.image}
                  title={article.name}
                  category={article.category}
                  description={article.description}
                  internalLink={article.internalLink}
                  link={article.link}
                  linkText="Read more"
                />
              ))}
            </AnimatePresence>
          )}
        </ThreePieceGrid>
      </Container>
      <SmallContainer>
        <P centered>
          For more articles and related topics visit Teva Life Effects and
          Asthma Society of Ireland.
        </P>
      </SmallContainer>
      <Container>
        <TwoPieceGrid>
          <SponsorCard
            image={LifeEffectsSponsorImage}
            title="Teva Life Effects"
            description="<p>Initiative shaped by patients, for patients. Life Effects tips and technologies to make things a little easier</p>"
            link="https://lifeeffects.teva/eu/"
            linkText="Visit Page"
          />
          <SponsorCard
            image={AsthmaSocietySponsorImage}
            title="Asthma Society"
            description="<p>We are the voice of the 380,000 people with asthma in Ireland, and our work radically transforms their quality of life.</p>"
            link="https://www.asthma.ie/"
            linkText="Read More"
          />
        </TwoPieceGrid>
      </Container>
      <Container>
        <Grid>
          <SizingCard />
        </Grid>
      </Container>
    </motion.main>
  );
};

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px;
  width: auto;
  background: ${({ active }) => (active ? "var(--teva-blue)" : "white")};
  color: ${({ active }) => (active ? "white" : "var(--teva-blue)")};
  font-weight: ${({ active }) => (active ? "600" : "400")};
  transition-duration: 0.25s;
  cursor: pointer;
  border: 1px solid var(--teva-blue) !important;
  border-radius: 0.25rem;
  line-height: 1.5em;
  margin: 0.75em;
  &:hover {
    border: 1px solid var(--teva-blue);
    background: ${({ active }) =>
      active ? "var(--teva-blue)" : "rgba(0, 127, 200, 0.1)"};
  }
`;

const ButtonRow = styled.div`
  width: 100%;
  grid-column: 1/7;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 3em;
  @media (min-width: 768px) {
    grid-column: 2/12;
  }
`;

export const query = graphql`
  query HCPResourcesQuery {
    allMarkdownRemark(
      filter: { frontmatter: { title: { eq: "hcp-resources" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            articles {
              id
              name
              category
              image {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              description
              link
              internalLink
            }
          }
        }
      }
    }
  }
`;

export default HCPResourcesPage;
